















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Accounting from '../../components/org/AccountingSettings.vue';
@Component({
  components: {
    Accounting,
  },
})
export default class AccountingClass extends BaseVue {
  public selectedTab = 0;
}
